import config from '../config/config'
import { axios } from './index'
const tabUrl = config.baseUrl + '/user/api/v1/tab'

export default {
  //根据人员标签及人员名称获取人员
  searchUser(payload) {
    return axios.post(`${tabUrl}/search/user?page=${payload.page}&size=${payload.size}&userName=${payload.userName}`, payload.tagIds)
  }
}
