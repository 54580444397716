import config from '../config/config'
// import axios from 'axios'
import { axios } from './index'

const baseUrl = config.baseUrl + '/user/api/v1/staff'
const staffBaseUrl = config.baseUrl + '/user/api/v2/staff'

export default {
  //批量移动员工
  moveBatchStaff(payload) {
    return axios.post(staffBaseUrl + '/move/batch', payload)
  },
  //添加员工
  insertStaff(payload) {
    return axios.post(baseUrl + '/insert', payload)
  },
  //搜索员工
  searchStaffs(payload, num, size) {
    return axios.post(`${baseUrl}/search?page=${num}&size=${size}`, payload)
  },
  //设置用户部门
  modifyDepartment(data, payload) {
    return axios.post(`${baseUrl}/modify/department?userId=${data}`, payload)
  },
  //重置密码
  resetPwd(data) {
    return axios.post(config.baseUrl + '/user/api/v1/user/manager/change/user/password', data)
  },
  //批量删除员工
  deleteStaff(data) {
    return axios.post(baseUrl + '/delete/batch', data)
  },
  //未整理
  getStaffsCount() {
    return axios.get(baseUrl + '/staffs/count')
  },
  addStaff(data) {
    return axios.post(baseUrl + '/add', data)
  },
  editStaff(data) {
    return axios.post(baseUrl + '/modify', data)
  },
  getDeletedStaff(params) {
    return axios.get(baseUrl + '/staffs/deleted', {
      params: params
    })
  },
  deleteShiftStaff(data) {
    return axios.post(baseUrl + '/delete/shift/batch', data)
  },
  restoreStaff(data) {
    return axios.post(baseUrl + '/restore/batch', data)
  },
  resetPassword(data) {
    // 目前这个接口 有问题
    // return axios.get(baseUrl + '/email/batch?userIds=' + data)
    // 新的接口
    return axios.post(baseUrl + '/reset/password/batch', data)
  },
  moveStaff(data) {
    return axios.post(baseUrl + '/move/batch', data)
  },
  getTabs() {
    return axios.get(baseUrl + '/tabs')
  },
  deleteTab(form) {
    return axios.post(baseUrl + '/tabs/delete', form)
  },
  // 导入员工
  importStarff(payload, formData) {
    return axios.post(`${baseUrl}/upload/excel?postId=${payload.postId}&departmentId=${payload.departmentId}`, formData)
  },
  // 下载导入模板
  downloadModelUrl: `${config.baseUrl}/user/api/v1/files/download/excel`,
  //根据部门和用户名称查询员工
  searchUser(payload) {
    return axios.post(`${baseUrl}/search/user?page=${payload.page}&size=${payload.size}&userName=${payload.userName}`, payload.departmentIds)
  }
}
