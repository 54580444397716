import config from '../config/config'
// import axios from 'axios'
import { axios } from './index'

const baseUrl = config.baseUrl + '/file/api/v1/oss/token'
const baseUrlFile = config.baseUrl + '/file'

export default {
  // upVideo(id, part, file) {
  //   return axios.post(
  //     config.baseUrl +
  //       `/file/api/v1/upload/course/video?courseId=${id}&partSize=${part}`,
  //     file
  //   )
  // },
  upVideo(id, part, file) {
    return axios({
      headers: {},
      method: 'post',
      url: `${config.baseUrl}/file/api/v1/upload/course/video?courseId=${id}&partSize=${part}`,
      data: file
    })
  },
  upCover(data) {
    return axios.post(config.baseUrl + '/file/api/v1/upload/cover', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  upFile(file) {
    return axios.post(`${baseUrlFile}/api/v1/upload/course/file`, file)
  },
  upType() {
    return axios.get(config.baseUrl + '/file/api/v1/upload/type')
  },
  getPaperBankCoverToken(paperBankId, name) {
    return axios.get(`${baseUrl}/cover/paper?paperId=${paperBankId}&name=${name}`)
  },
  getQuestionBankCoverToken(questionBankId, name) {
    return axios.get(`${baseUrl}/cover/question?questionId=${questionBankId}&name=${name}`)
  },
  getLiveCoverToken(liveId, name) {
    return axios.get(`${baseUrl}/cover/live?liveId=${liveId}&name=${name}`)
  },
  getFileToken(folderId, name) {
    return axios.get(`${baseUrl}/file?folderId=${folderId}&name=${name}`)
  },
  getCourseCoverToken(data) {
    return axios.get(`${baseUrl}/cover/course?courseId=${data.courseId}&name=${data.name}`)
  },
  getQuestionFileToken(name) {
    return axios.get(`${baseUrl}/file/question?name=${name}`)
  },
  //获取上传课程分类封面token
  getCourseCategory(categoryId, name) {
    return axios.get(`${baseUrl}/cover/course-category?categoryId=${categoryId}&name=${name}`)
  },
  //下载简历
  downloadResumeFile(payload) {
    return axios.post(`${baseUrlFile}/api/v1/oss/download/resume/file`, payload, {
      responseType: 'blob'
    })
  }
}
